module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ScottSutherlandWebDev","short_name":"Landing","start_url":"/gatsby-starter-solidstate/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/images/ss-icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29e7532fc27e0750e72b0f67ff257f6b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
